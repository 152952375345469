<template>
    <div class="combine-card">
        <div class="c-l">
            <div class="c-l-t">
                作品信息
            </div>
            <div class="c-l-i">
                <div class="label">作品名称：</div>
                <div class="value">
                    <el-input v-if="editor" ref="edit" v-model="cardInfo.name" style="width: 140px;" placeholder="请输入名称" size="mini" @blur="editConfirm" @keyup.enter.native="editConfirm"></el-input>
                    <span v-if="!editor">{{cardInfo.name}} </span>
                    <i v-if="!editor" class="el-icon-edit" style="color: #999999;margin-left:4px;" @click="editBtn"></i>
                </div>
            </div>
            <div class="c-l-i">
                <div class="label">作品分类：</div>
                <div class="value">
                    <span class="blue" v-for="(item,index) in classifyList" :key="index">{{ item }} <i class="el-icon-close" @click="delBtn(index)"></i></span>
                    <el-input v-if="addClassify" ref="add" v-model="classify" style="width: 140px;" placeholder="请输入分类名称" size="mini" @blur="addConfirm" @keyup.enter.native="addBlur"></el-input>
                    <div class="add" v-else @click="addBtn">+</div>
                </div>
            </div>
            <div class="c-l-i">
                <div class="label">作品状态：</div>
                <div class="value">
                    <span class="status yellow" v-if="cardInfo.status==1">创作中</span>
                    <span class="status yellow" v-if="cardInfo.status==2">合成中</span>
                    <span class="status yellow" v-if="cardInfo.status==3">排队中</span>
                    <span class="status red" v-if="cardInfo.status==4">创作失败</span>
                    <span class="status green" v-if="cardInfo.status==5">创作成功</span>

                </div>
            </div>
            <div class="c-l-i">
                <div class="label">发音人：</div>
                <div class="value">
                    <span class="gray" v-if="locality">
                        本地音视频
                        <el-tooltip effect="dark" content="当前作品通过本地音视频配音" placement="top">
                            <img src="@/assets/images/workben/question.png" alt="">
                        </el-tooltip>
                    </span>
                    <span class="gray" v-else>
                        AI (好妹妹)
                    </span>
                </div>
            </div>
            <div class="c-l-i">
                <div class="label">宽高比：</div>
                <div class="value">1080x1920</div>
            </div>
            <div class="c-l-i">
                <div class="label">分辨率：</div>
                <div class="value">1080x1920</div>
            </div>
            <div class="c-l-i">
                <div class="label">码率：</div>
                <div class="value">3.5M</div>
            </div>
        </div>
        <div class="c-r">
            <div class="video">
                <div class="loading-img" v-if="type!=3">
                    <img src="@/assets/images/digital/loading.png" alt="">
                </div>
                <div class="text" v-if="type!=3">
                    {{type==1?'任务排队中，即将开始…':type==2?'数据准备中':''}}
                </div>
                <div class="btn" v-if="type==1">取消合成</div>
                <div class="tips" v-if="type!=3">
                    离开页面不影响合成，可在作品管理/视频作品/我的草稿中查看合成进度
                </div>
                <div class="play" v-if="type==3">
                    <img src="@/assets/images/digital/play.png" alt="">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            editor: false,
            addClassify: false,
            classifyList: ['分类1'],
            classify: '',
            type: 3,
            locality: true,
            id: '',
            cardInfo: {
                name: "--",
                status: 1,
            },
        };
    },
    created() {
        if (this.$route.query.id) {
            this.id = this.$route.query.id
            this.getInfo()
        }
    },
    methods: {
        // 添加分类
        addBtn() {
            this.addClassify = true
            this.$nextTick(() => {
                this.$refs.add.focus()
            })
        },
        // 确定添加
        addConfirm() {
            if (this.classify != '') {
                this.classifyList.push(this.classify)
            }
            this.addClassify = false
            this.classify = ''
        },
        // 删除分类
        delBtn(index) {
            this.classifyList.splice(index, 1);
        },
        addBlur() {
            this.$refs.add.blur()
        },
        // 修改名称
        editBtn() {
            this.editor = true
            this.$nextTick(() => {
                this.$refs.edit.focus()
            })
        },
        // 确定修改
        editConfirm() {
            this.editor = false
        },
        getInfo() {
            this.$digitalApi.creatCardInfo(this.id).then(res => {
                console.log(res)
                if (res.code == 1000) {
                    this.cardInfo = res.result
                } else {
                    this.$errMsg(res.message)
                }
            })
        },
    },
};
</script>

<style scoped lang="scss">
.combine-card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 1300px;
    .c-l {
        width: 342px;
        height: 819px;
        background: #ffffff;
        border-radius: 10px;
        padding: 30px 20px;
        .c-l-t {
            font-size: 18px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
            line-height: 25px;
            padding-bottom: 14px;
            border-bottom: 1px solid #ededed;
        }
        .c-l-i {
            display: flex;
            margin-top: 30px;
            line-height: 26px;
            .label {
                font-size: 14px;
                color: #333333;
                width: 78px;
            }
            .value {
                font-size: 14px;
                color: #333333;
                width: 200px;
                i {
                    cursor: pointer;
                }
                .status {
                    font-size: 12px;
                    padding: 4px 8px;
                    border-radius: 13px;
                }
                .blue {
                    background: #e6e9fe;
                    border-radius: 13px;
                    padding: 0 8px;
                    color: #2e4bf2;
                    font-size: 12px;
                    margin-right: 10px;
                    display: inline-block;
                    line-height: 26px;
                    margin-bottom: 6px;
                }
                .add {
                    width: 22px;
                    height: 22px;
                    background: #e6e9fe;
                    text-align: center;
                    line-height: 22px;
                    color: #2e4bf2;
                    font-weight: bold;
                    border-radius: 50%;
                    display: inline-block;
                    cursor: pointer;
                }
                .yellow {
                    background: rgba(255, 116, 50, 0.12);
                    color: #ff7432;
                }
                .red {
                    color: #f76767;
                    background: #fef0f0;
                }
                .green {
                    color: #68c23a;
                    background: #f0f9eb;
                }
                .gray {
                    padding: 0 8px;
                    background: #eaeaea;
                    border-radius: 4px;
                    color: #595959;
                    font-size: 12px;
                    line-height: 26px;
                    display: inline-flex;
                    align-items: center;
                    img {
                        width: 12px;
                        height: 12px;
                        display: inline-block;
                        margin-left: 4px;
                    }
                }
            }
        }
    }
    .c-r {
        width: calc(100% - 372px);
        height: 819px;
        background: #ffffff;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        .video {
            width: 900px;
            height: 506px;
            background: #000000;
            opacity: 0.6;
            position: relative;
            .loading-img {
                width: 56px;
                height: 56px;
                position: absolute;
                top: 178px;
                left: 50%;
                transform: translate(-50%, 0);
                img {
                    width: 56px;
                    height: 56px;
                    animation: loading-img 1s infinite ease-in-out;
                }
            }
            .text {
                position: absolute;
                top: 254px;
                left: 50%;
                transform: translate(-50%, 0);
                font-size: 16px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #ffffff;
                line-height: 22px;
            }
            .play {
                width: 60px;
                height: 60px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                cursor: pointer;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
            .btn {
                position: absolute;
                top: 296px;
                left: 50%;
                transform: translate(-50%, 0);
                width: 76px;
                height: 32px;
                border-radius: 4px;
                border: 1px solid #c1c1c1;
                text-align: center;
                line-height: 30px;
                color: #ffffff;
                cursor: pointer;
                font-size: 14px;
            }
            .tips {
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #ffffff;
                line-height: 17px;
                text-align: center;
                width: 230px;
                position: absolute;
                bottom: 30px;
                left: 50%;
                transform: translate(-50%, 0);
            }
        }
    }
}
@keyframes loading-img {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
</style>
